import {Component, HostListener, signal} from '@angular/core';
import {MenuService} from "../../services/menu.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {MatIcon} from "@angular/material/icon";
import {AuthService} from "../../services/auth/auth.service";
import {User} from "../../../admin/models/users.model";
import {NavbarMobileComponent} from "./navbar-mobile/navbar-mobile.component";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [NgClass, RouterLink, MatIcon, NavbarMobileComponent, NgIf, NgForOf],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          opacity: 1,
          transform: 'translateY(0)',
          visibility: 'visible',
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
          transform: 'translateY(-20px)',
          visibility: 'hidden',
        }),
      ),
      transition('open => closed', [animate('0.2s')]),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
})
export class NavbarComponent {
  userAuth = signal<any>([])
  user = signal<User | null>(null)
  statNotif = signal<boolean>(false)
  constructor(private menuService: MenuService, private  authService: AuthService, private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      this.user.set(this.route.snapshot.data['user'].data)
      if (this.user()){
        this.userAuth.set(this.user());
      }

    }
  }

public toggleNotification(){
    this.statNotif.set(!this.statNotif())
  console.log(this.statNotif())
}

  public toggleMobileMenu(): void {
    this.menuService.showMobileMenu = true;
  }
  public isOpen = false;
  public profileMenu = [
    {
      title: 'Profil',
      icon: 'M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z',
      link: '/profile/me',
      active: true,
      action: () => {
        this.isOpen = !this.isOpen
        this.userAuth().plateform === "BACKOFFICE_ADMIN"
          ? this.router.navigate(['/admin/profile/me']).then(r => console.log('Admin Profile...'))
          : this.router.navigate(['/company/profile/me']).then(r => console.log('Company Profile...'));
      },
      description:"Pas encore disponible"
    },
    {
      title: 'Log out',
      icon: 'M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9',
      link: '/auth',
      active: true,
      action:()=>{this.logout()},
      description:"Se déconnecter"
    },
  ];

  public toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  public toggleSidebar() {
    this.menuService.toggleSidebar();
  }

  public  logout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  // @HostListener('window:beforeunload', ['$event'])
  // unloadHandler(event: Event) {
  //   this.logout();
  // }

}
