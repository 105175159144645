<div class="bg-cover bg-center min-h-screen flex flex-col items-center justify-center py-6 px-4 font-[sans-serif]" style="background-image: url('assets/images/bg-auth-4-compress.jpg');">
  <div class="absolute inset-0 bg-black opacity-80"></div>
  <div class="max-w-md w-full z-10">
    <div class="p-8 rounded-xl bg-white shadow-lg">
      <h2 class="text-[#e3a602] text-center flex justify-center text-2xl font-bold">
        <img src="/assets/images/LogoPrimeJaune.png" class="w-[130px]" alt="">
      </h2>
      <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="mt-8 space-y-4">
        <div>
          <label class="text-gray-800 text-sm mb-2 block">Nom d'utilisateur</label>
          <div class="relative flex items-center">
            <input class="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600" formControlName="login" placeholder="Entrer votre nom d'utilisateur" required type="text" />
            <svg class="w-4 h-4 absolute right-4" fill="#bbb" stroke="#bbb" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="7" data-original="#000000" r="6"></circle>
              <path d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z" data-original="#000000"></path>
            </svg>
          </div>
        </div>
        <div>
          <label class="text-gray-800 text-sm mb-2 block">Mot de passe</label>
          <div class="relative flex items-center">
            <input class="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600" formControlName="password" placeholder="Entrer mot de passe" required [type]="showPassword ? 'text':'password'" />
            <svg (click)="googlePassword()" class="w-4 h-4 absolute right-4 cursor-pointer" fill="#bbb" stroke="#bbb" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
              <path d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z" data-original="#000000"></path>
            </svg>
          </div>
        </div>
        <div class="!mt-8">
          <button
            [disabled]="login"
            [ngClass]="{
               'bg-[#e3a602] text-white hover:text-[#e3a602] hover:bg-white hover:border-2 hover:border-[#e3a602]': !login,
            'bg-gray-400 text-gray-300 cursor-not-allowed': login
           }"
            class="w-full py-3 px-4 text-sm font-semibold tracking-wide rounded-lg focus:outline-none transition duration-300"
            type="submit"
          >
            Se connecter
          </button>
        </div>

      </form>
    </div>
  </div>
</div>
