import {NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./shared/auth/login/login.component";
import {AuthGuard} from "./shared/guards/auth/auth.guard";
import {LayoutComponent} from "./shared/layout/layout.component";
import { UserResolver } from './shared/resolver/user-resolver';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login', // Redirige vers /login
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    resolve: { user: UserResolver },
    component: LayoutComponent,
    children: [
      {
        path: 'admin',
        resolve: { user: UserResolver },
        loadChildren: () => import('./admin/routes/Admin.module').then(m => m.AdminRoutingModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'company',
        resolve: { user: UserResolver },
        loadChildren: () => import('./company/routes/Company.module').then(m => m.CompanyRoutingModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }




