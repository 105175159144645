<div class="relative bg-white h-[65px] flex-none rounded-2xl shadow-sm">
  <div class="mx-auto pl-4 mr-2 h-full flex items-center justify-end">
    <div class="flex items-center justify-between py-2 lg:justify-start w-full ">
      <!-- Mobile Navigation Menu Button-->
      <div class="md:order-1 lg:hidden">
        <button
          (click)="toggleMobileMenu()"
          type="button"
          class="inline-flex items-center justify-center rounded-md bg-muted p-2 text-muted-foreground focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary hover:bg-muted-foreground hover:text-muted"
          aria-expanded="false">
          <span class="sr-only">Open menu</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
          </svg>
          <!-- Heroicon name: outline/menu -->
<!--          <svg-icon src="assets/icons/heroicons/outline/menu.svg" [svgClass]="'h-6 w-6'"> </svg-icon>-->
        </button>
      </div>

      <!-- Logo -->
      <div class="flex items-center justify-start md:order-2 lg:hidden">
        <a [href]="userAuth().plateform === 'BACKOFFICE_ADMIN' ? '/admin': 'company'" class="flex items-center cursor-pointer justify-center rounded bg-primary p-2 focus:outline-none focus:ring-1">
          <img src="/assets/images/LogoPrimeJaune.png" class="w-[80px]" alt="">
        </a>
      </div>

      <!-- Desktop Menu -->
      <div class="hidden space-x-10 sm:order-3 lg:flex">
       <button
         (click)="toggleSidebar()"
       >
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
           <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
         </svg>
       </button>
      </div>

      <!-- Profile menu -->
      <div class="items-center justify-end sm:order-4 lg:flex lg:flex-1 sm:flex gap-3">
<!--        @if(userAuth().plateform === 'BACKOFFICE_ADMIN'){-->
<!--        <div class="relative inline-flex" >-->
<!--          <button-->
<!--            (click)="toggleNotification()"-->
<!--            class="flex items-center rounded-2xl bg-slate-100 py-2.5 px-3 border border-transparent text-center text-sm text-slate-800 transition-all shadow-sm hover:shadow-sm  active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">-->
<!--              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />-->
<!--            </svg>-->
<!--          </button>-->
<!--          <span class="absolute top-0.5 right-0.5 grid min-h-[24px] min-w-[24px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-500 py-1 px-1 text-xs font-medium leading-none text-white content-['']">-->
<!--      5-->
<!--    </span>-->
<!--          <div-->
<!--            *ngIf="statNotif()"-->
<!--            class="absolute right-[-8px] z-20 top-14 w-[300px] origin-top-right transform flex flex-col gap-3 items-start rounded-xl shadow bg-white py-4  ring-1 ring-transparent ring-opacity-5 transition focus:outline-none">-->
<!--            <span class="text-sm font-medium flex items-center gap-2 px-4">Messages<span class="text-xs text-[#e3a608] bg-[#e3a608]/10 p-1 rounded-xl">5 nouveaux</span></span>-->

<!--            <div *ngFor="let item of [1,2,3,4]" class="w-full flex flex-col border-t border-slate-100 hover:bg-slate-100 px-4 py-2">-->
<!--              <span class="text-sm font-medium text-slate-800">Sed ut perspiciatis unde</span>-->
<!--              <span class="text-xs font-normal text-slate-600">Inventore veritatis et quasi arc</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        }-->
        <div class="relative py-3 px-4 bg-[#e3a608]/10 rounded-2xl ">
          <!-- Profile Button -->
          <button (click)="toggleMenu($event)" class="flex items-center bg-card text-sm text-slate-800" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 mb-1">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z" />
            </svg>
            <div class="overflow-hidden px-2 text-sm font-normal uppercase">
              @if(userAuth().plateform === "BACKOFFICE_COMPANY"){
                {{userAuth().company?.name}}
              } @else {
                {{"Prime"}}
              }
            </div>
            <span class="text-slate-600">
              @if(isOpen){
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
              </svg>
              }@else{
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
              }
            </span>
          </button>
          <!-- Dropdown -->
          <div
            [@openClose]="isOpen ? 'open' : 'closed'"
            (click)="onDropdownClick($event)"
            class="absolute right-[-8px] z-20 mt-6 w-60 origin-top-right transform  rounded-lg shadow bg-white py-4 ring-1 ring-transparent ring-opacity-5 transition focus:outline-none">
            <div class="flext-row flex items-center px-4 pb-4">

              <div class="overflow-hidden px-2 text-xs font-normal text-slate-800">
                {{userAuth().firstname}}  {{userAuth().lastname}}
                <p class="truncate text-ellipsis text-xs font-normal text-slate-800"> {{userAuth().email}}</p>
              </div>
            </div>

            <div class="border-b border-dashed border-[#e3a608]/50"></div>

            <ul class="my-2 pr-2 flex flex-col">
              @for (item of profileMenu; track $index) {
                <li
                  :key="$index"
                  class="inline-flex items-center gap-2 rounded-md px-3 py-2 text-[14px] text-slate-800 cursor-pointer font-normal group"
                  [ngClass]="{'cursor-not-allowed': !item.active,'cursor-pointer':item.active}" [title]="item?.description"
                  (click)="item.action()">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round" [attr.d]="item.icon" />
                  </svg>
                  {{ item.title }}
                </li>
              }
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- Mobile menu -->
  <app-navbar-mobile [user]="userAuth()"></app-navbar-mobile>
</div>
