import {Component, signal} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from '../../services/auth/auth.service';
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, NgClass, NgOptimizedImage],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  loginForm: FormGroup;
  loginError = signal<string | null>(null);  // Définir le signal pour gérer les erreurs de login
  loginSuccess = signal<boolean>(false);
  user: any;
  login: boolean = false;

  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder) {
    this.loginForm = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async onSubmit() {
    this.login = true;
    if (this.loginForm.valid) {
      const { login, password } = this.loginForm.value;
      try {
        const response: any = await this.authService.login(login, password);
        this.login = false;
        if (response) {
          this.loginSuccess.set(true);
          this.loginError.set(null);
          if (response.plateform === 'BACKOFFICE_ADMIN') {
            await this.router.navigate(['/admin']);
          } else if (response.plateform === 'BACKOFFICE_COMPANY') {
            await this.router.navigate(['/company']);
          }
        }
      } catch (error) {
        this.loginSuccess.set(false);
        this.loginError.set('Login échoué, veuillez réessayer.');
        this.login = false;
        console.error(error);
      }
    }
  }


  ngOnInit(): void {

  }
  showPassword:boolean=false;
  googlePassword() {
    this.showPassword =!this.showPassword
  }
}
