import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient } from '@angular/common/http';


import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideToastr} from "ngx-toastr";

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes),
    provideHttpClient(), provideAnimationsAsync(), provideToastr({timeOut: 8000, positionClass: 'toast-top-right', closeButton: true,  preventDuplicates: true, toastClass: 'ngx-toastr toast-outlined', easeTime: 300,})
  ]
};
