import {Component, OnInit, signal} from '@angular/core';
import {CommonModule, NgClass, NgIf} from "@angular/common";
import {AngularSvgIconModule} from "angular-svg-icon";
import {MenuService} from "../../services/menu.service";
import {SubMenuItem} from "../../models/menu.model";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {SidebarSubmenuComponent} from "./sidebar-submenu/sidebar-submenu.component";
import {User} from "../../../admin/models/users.model";
import {FilterApiModel} from "../../../company/models/FilterApi.model";
import {of, tap} from "rxjs";
import {catchError} from "rxjs/operators";
import {EmployeeService} from "../../../company/services/employee.service";


@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgClass, NgIf, AngularSvgIconModule, RouterLink, CommonModule, RouterLinkActive, SidebarSubmenuComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent implements OnInit {
  userAuth = signal<any>([])
  user = signal<User | null>(null)
  employee: User[]  = []
  errorMessage: string = '';

  constructor(public menuService: MenuService, protected authService: AuthService, private router: Router, private route: ActivatedRoute, private employeeService: EmployeeService) {}

  ngOnInit(): void {
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      this.user.set(this.route.snapshot.data['user'].data)
      if (this.user()){
        this.userAuth.set(this.user());
      }

    }
if (this.user()?.plateform === 'BACKOFFICE_ADMIN'){
  this.loadEmployee()
}

  }


  loadEmployee(){
    this.employeeService.paginatedEmployeesWithoutCompanyFiltre(this.filterRsql).pipe(
      tap((data: any) => {
        this.employee = data.data;
      }),
      catchError((error: any) => {
        this.errorMessage = error;
        return of([]);
      })
    ).subscribe();

  }

  protected readonly FilterApiModel = FilterApiModel;
  filterRsql:FilterApiModel = new FilterApiModel(1,10,"kyc=='KYC_REQUEST_2',signatureContractStatus=='SIGNED_REQUEST'");

  public toggleMenu(subMenu: SubMenuItem) {
    this.menuService.toggleMenu(subMenu);
  }

  public  logout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
