import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error.status === 401) {
          this.toastr.error('Unauthorized access. Please log in.', 'Error 401');
        } else if (error.error.status === 500) {
          this.toastr.error('Internal server error. Please try again later.', 'Error 500');
        } else {
          this.toastr.error('An unknown error occurred.', `Error ${error.error.status}`);
        }

        return throwError(() => error);
      })
    );
  }
}
