<div class="flex h-screen w-full bg-slate-100 pl-5 pt-5 pb-5 overflow-hidden">
  <!-- Sidebar -->
  <app-sidebar></app-sidebar>
  <!-- Main content container -->
  <div class="flex grow flex-col mx-auto lg:container px-6 sm:px-8 w-full items-center overflow-x-auto">
    <!-- Header -->
    <!-- Main content -->
    <div class="flex flex-col w-full overflow-hidden">
      <!-- Navbar -->
      <app-navbar></app-navbar>
      <!-- Main content -->
      <div id="main-content" class="mt-2 grow pb-1 overflow-auto scrollbar-hide scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted">
        <div class="mx-auto mt-5 max-h-screen ">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <!-- Optional Footer or Bottom bar -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>

