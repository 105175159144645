import { Component } from '@angular/core';
import {MenuService} from "../../../services/menu.service";
import {CommonModule, NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../../../services/auth/auth.service";
import {SidebarSubmenuComponent} from "../../sidebar/sidebar-submenu/sidebar-submenu.component";
import {SubMenuItem} from "../../../models/menu.model";

@Component({
  selector: 'app-navbar-mobile',
  standalone: true,
    imports: [
        NgClass,
        NgForOf,
        NgIf,
        RouterLink,
        RouterLinkActive,
        NgTemplateOutlet,
        CommonModule,
        SidebarSubmenuComponent
    ],
  templateUrl: './navbar-mobile.component.html',
  styleUrl: './navbar-mobile.component.css'
})
export class NavbarMobileComponent {
  constructor(public menuService: MenuService, protected authService: AuthService) {}

  ngOnInit(): void {}


  public toggleMobileMenu(): void {
    this.menuService.showMobileMenu = false;
  }

  public toggleMenu(subMenu: SubMenuItem) {
    this.menuService.toggleMenu(subMenu);
  }

  public closeMenu() {
    this.menuService.showMobileMenu = false;
  }


}
