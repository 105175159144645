import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';


@Component({
  selector: 'app-redirect',
  standalone: true,
  imports: [],
  template: `

  `
})
export class RedirectComponent implements OnInit {
  user: any;
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const jwtToken = localStorage.getItem('jwtToken');

    if (jwtToken) {
      this.user = this.route.snapshot.data['user'].data;
      if (this.user){
        if (this.user.plateform === 'BACKOFFICE_ADMIN') {
        this.router.navigate(['/admin']);
          } else if (this.user.plateform === 'BACKOFFOCE_COMPANY') {
            this.router.navigate(['/company']);
          } else {
          this.router.navigate(['/login']);
      }
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
}
