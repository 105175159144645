import {Component, Input} from '@angular/core';
import {SubMenuItem} from "../../../models/menu.model";
import {MenuService} from "../../../services/menu.service";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-sidebar-submenu',
  standalone: true,
  imports: [
    RouterLinkActive,
    RouterLink,
    NgTemplateOutlet,
    NgForOf,
    NgClass,
    NgIf
  ],
  templateUrl: './sidebar-submenu.component.html',
  styleUrl: './sidebar-submenu.component.css'
})
export class SidebarSubmenuComponent {
  @Input() public submenu = <SubMenuItem>{};



  constructor(public menuService: MenuService, protected authService: AuthService) {}

  ngOnInit(): void {}

  public toggleMenu(menu: any) {
    this.menuService.toggleSubMenu(menu);
  }

  public toggleMobileMenu(): void {
    this.menuService.showMobileMenu = false;
  }

  private collapse(items: Array<any>) {
    items.forEach((item) => {
      item.expanded = false;
      if (item.children) this.collapse(item.children);
    });
  }

}
