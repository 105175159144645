<div
  [ngClass]="menuService.showSideBar ? 'w-[17rem]' : 'w-[60px]'"
  class="scrollbar-thumb-rounded scrollbar-track-rounded overflow-hidden hidden rounded-2xl shadow h-full flex-col justify-between bg-white pt-3 transition-all duration-300 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-card lg:flex">
  <div class=" flex flex-col h-full">
    <!-- Logo -->
    <div class="relative h-10 ml-4">
      <div class="flex items-center" *ngIf="menuService.showSideBar">
        <a
          class="flex cursor-pointer items-center justify-center rounded bg-primary p-2 focus:outline-none focus:ring-1">
          <img src="/assets/images/LogoPrimeJaune.png" class="w-[80px]" alt="">
        </a>
      </div>
      <span
        *ngIf="!menuService.showSideBar"
        class="absolute top-2 right-1 w-[50px] flex justify-center items-center rounded text-slate-800 transition-all duration-200 focus:outline-none hover:text-muted-foreground">
       <img src="assets/prime.ico.png" class="w-8" alt="">
      </span>
    </div>
    <!-- Separator -->
    <div class="pt-3">
      <hr class="border size-2/3 border-[#e3a608]/20" />
    </div>

    <div class="pt-5" *ngFor="let menu of menuService.pagesMenu">
      <ul class="flex flex-col gap-1 space-y-1 w-full">
        @for (item of menu.items; track item.label){
          <li
            *ngIf="!item.routePermission || authService.hasPermission(item.routePermission)"
          >
            <a
              class="group relative w-full text-muted-foreground flex items-center pl-4 focus:outline-none"
              (click)="toggleMenu(item)"
              [routerLink]="item.route ? item.route : null"
              [ngClass]="{'disabled-link': !item.route}"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">

              <!-- Icône -->
              <div class="pointer-events-none ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 menu-icon">
                  <path stroke-linecap="round" stroke-linejoin="round" [attr.d]="item.icon" />
                </svg>
              </div>

              <!-- Étiquette -->
              <div class="flex h-9 cursor-pointer items-center justify-start rounded text-muted-foreground hover:bg-card hover:text-foreground">
                <a class="ml-6 truncate tracking-wide focus:outline-none text-xs font-normal text-slate-800">
                  {{ item.label }}
                </a>
<!--                <span class="flex items-center justify-center p-1 ml-2 text-xs font-medium text-[#e3a602] bg-[#e3a602]/20 rounded-full"> 15 </span>-->
              </div>
              <!-- Arrow Icon -->
              <button
                *ngIf="item.children"
                [ngClass]="{ hidden: !menuService.showSideBar, 'rotate-90': item.expanded }"
                class="pointer-events-none absolute top-1 right-0 flex items-center p-1 text-muted-foreground/50 transition-all duration-500">
                <span class="material-symbols-outlined icon" style="font-size: 22px">
                  chevron_right
                </span>
              </button>
              <!-- Tooltip -->
              <div class="fixed w-full" *ngIf="!menuService.showSideBar">
      <span class="z-1 absolute left-14 -top-[34px] w-auto min-w-max origin-left scale-0 rounded-md bg-foreground p-2 text-xs font-bold text-white bg-black shadow-md transition-all duration-200 group-hover:scale-100">
        {{ item.label }}
      </span>
              </div>
            </a>
            <app-sidebar-submenu [submenu]="item"></app-sidebar-submenu>
          </li>
        }
      </ul>

      <div class="pt-3" *ngIf="menu.separator">
        <hr class="border-dashed border-border" />
      </div>
    </div>
  </div>
  @if(menuService.showSideBar){
    <div class="self-end w-full h-[100px] p-3  ">
      <div class="w-full h-full rounded-2xl bg-[#e3a608]/10 flex items-center justify-between gap-3 px-4">
        <div class="flex items-center gap-3">
          <img [src]="userAuth().avatarPath === null || !userAuth().avatarPath ? '/assets/images/user5.jpg' : userAuth().avatarPath" alt="" srcset="" class="w-10 h-10 rounded-full object-cover">
          <span class="flex flex-col gap-1">
       <span class="text-slate-800 text-xs font-medium capitalize truncate w-32">{{userAuth().firstname}} {{userAuth().lastname}}</span>
        <span class="text-slate-700 text-xs font-normal">{{userAuth().role?.name}}</span>
      </span>
        </div>
        <button
          (click)="logout()"
          class="text-[#e3a608]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
          </svg>
        </button>

      </div>
    </div>

  }

</div>
