import {Component, signal} from '@angular/core';
import {CommonModule, NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {AngularSvgIconModule} from "angular-svg-icon";
import {MenuService} from "../../services/menu.service";
import {MatIcon} from "@angular/material/icon";
import {SubMenuItem} from "../../models/menu.model";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {SidebarSubmenuComponent} from "./sidebar-submenu/sidebar-submenu.component";
import {User} from "../../../admin/models/users.model";


@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgClass, NgIf, AngularSvgIconModule, MatIcon, NgTemplateOutlet, RouterLink, CommonModule, RouterLinkActive, SidebarSubmenuComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {
  userAuth = signal<any>([])
  user = signal<User | null>(null)
  constructor(public menuService: MenuService, protected authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      this.user.set(this.route.snapshot.data['user'].data)
      if (this.user()){
        this.userAuth.set(this.user());
      }

    }
  }


  public toggleMenu(subMenu: SubMenuItem) {
    this.menuService.toggleMenu(subMenu);
  }

  public  logout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
