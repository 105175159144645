import {Resolve} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";
import {Observable, tap} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<any> {

  constructor(private authService: AuthService) {}
  resolve(): Observable<any> {
    return this.authService.loadUser().pipe(
      tap((response: any) => {
        return response.data
      })
    );
  }
}
